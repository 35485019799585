<template>
	<div class="menu-button">
		<TnIcon
			name="close"
			size="m"
			v-if="open"
		></TnIcon>
		<TnIcon
			name="menu"
			size="l"
			v-else
		></TnIcon>
	</div>
</template>

<script>
import SupportsDarkMode from "../../../../../mixins/SupportsDarkMode";
export default defineNuxtComponent({
	name: "MenuButton",
	props: ["open"],
	mixins: [SupportsDarkMode],
});
</script>

<style lang="scss" scoped>
.menu-button {
	display: flex;
	justify-content: center;
	align-items: center;
	padding-left: $spacing-m;
	min-width: 48px;

	@include breakpoint(mobile) {
		padding-right: 0;
	}
}
</style>
